import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Button, Col, Row } from 'react-bootstrap';
import Skeleton from '@mui/material/Skeleton';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LdcApi from '../../../LdcApis';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import Navbar from '../../../layouts/AdminLayout/NavBar';

const { RangePicker } = DatePicker;

function MarketingDataReport() {
  const [serviceReportData, setServiceReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const gridRef = useRef();
  const [filteredData, setFilteredData] = useState([]);
  const formatDisplay = 'DD/MM/YYYY';
  const formatAPI = 'YYYY/MM/DD';
  const todayDisplay = dayjs().format(formatDisplay);
  const todayAPI = dayjs().format(formatAPI);
  const threeMonthsAgoDisplay = dayjs()
    .subtract(3, 'months')
    .format(formatDisplay);
  const threeMonthsAgoAPI = dayjs().subtract(3, 'months').format(formatAPI);

  const [displayStartDate, setDisplayStartDate] = useState(
    threeMonthsAgoDisplay
  );
  const [displayEndDate, setDisplayEndDate] = useState(todayDisplay);
  const [apiStartDate, setApiStartDate] = useState(threeMonthsAgoAPI);
  const [apiEndDate, setApiEndDate] = useState(todayAPI);

  useEffect(() => {
    document.title = 'Marketing Report Data - LDC';
    getPasword();
  }, [apiStartDate, apiEndDate]);

  const getPasword = () => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Data/GetConsentMarketingData?startDate=${apiStartDate}&endDate=${apiEndDate}`,
    })
      .then((res) => {
        setServiceReportData(res.data.data);
        filterTodayData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  const [columnDefs] = useState([
    {
      headerName: 'Patient Name',
      field: 'name',
      width: 300,
      minWidth: 100,
      filter: true,
      sortable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 300,
      rowHeight: 200,
      minWidth: 100,
      filter: true,
      sortable: true,
    },
    {
      headerName: 'Mobile',
      field: 'mobile',
      width: 300,
      minWidth: 100,
      filter: true,
      sortable: true,
    },
  ]);

  const filterTodayData = (data) => {
    const todayFormatted = dayjs().format(formatDisplay);
    const todayData = data.filter(
      (item) => item.appointmentDate === todayFormatted
    );
    setFilteredData(todayData);
  };

  const onDateRangeChange = (dates, dateStrings) => {
    if (dates === null) {
      setDisplayStartDate(threeMonthsAgoDisplay);
      setDisplayEndDate(todayDisplay);
      setApiStartDate(threeMonthsAgoAPI);
      setApiEndDate(todayAPI);
    } else {
      setDisplayStartDate(dateStrings[0]);
      setDisplayEndDate(dateStrings[1]);
      setApiStartDate(dayjs(dateStrings[0], formatDisplay).format(formatAPI));
      setApiEndDate(dayjs(dateStrings[1], formatDisplay).format(formatAPI));
    }
  };

  const onBtnExport = useCallback(() => {
    const transformedData = serviceReportData.map((item) => ({
      'Patient Name': item.name,
      Email: item.email,
      Mobile: item.mobile,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Service Report');

    const wscols = [{ wch: 30 }, { wch: 30 }, { wch: 20 }];
    worksheet['!cols'] = wscols;

    XLSX.writeFile(workbook, 'Marketing_Report.xlsx');
  }, [serviceReportData]);

  return (
    <div>
      <Row>
        <h8 style={{ marginTop: '3px', marginLeft: '15px' }}>Date Range :</h8>
        <Col>
          <RangePicker
            onChange={onDateRangeChange}
            format={formatDisplay}
            value={[
              dayjs(displayStartDate, formatDisplay),
              dayjs(displayEndDate, formatDisplay),
            ]}
          />
          <Button
            type='primary'
            onClick={getPasword}
            style={{ backgroundColor: 'Blue', marginLeft: '10px' }}
          >
            Show Data
          </Button>
        </Col>
        <Col>
          <div className='d-flex mr-3 justify-content-end'>
            <Button className='btn btn-primary' onClick={onBtnExport}>
              Export <MdOutlineSimCardDownload style={{ color: '#fff' }} />
            </Button>
          </div>
        </Col>
      </Row>
      <div
        className='ag-theme-alpine mt-2'
        style={{ height: '80vh', width: '100%' }}
      >
        {loading ? (
          <div className='rounded'>
            {[...Array(20)].map((_, index) => (
              <Skeleton key={index} height={60} sx={{ bgcolor: '#e2e8f0' }} />
            ))}
          </div>
        ) : (
          <AgGridReact
            ref={gridRef}
            rowData={serviceReportData}
            columnDefs={columnDefs}
            animateRows={true}
            pagination={true}
            paginationPageSize={50}
            rowHeight={60}
          />
        )}
      </div>
    </div>
  );
}

export default MarketingDataReport;
