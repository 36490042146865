import { capitalize, Skeleton } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import LdcApi from '../../../LdcApis';
import Patients from '../../../modules/Patients';

const ProfileBar = ({ setPar, getProfileData, userData }) => {
  let patientDetailsData = JSON.parse(localStorage.getItem('patientDetailsData'));
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));

  const { id, viewId } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [patientAptData, setPatientAptData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLaoding] = useState(false);

  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if(!userData){
      setLaoding(true);
      LdcApi({
        method: 'GET',
        url: `Patients/GetPatientsById?id=${id}`
      })
        .then((response) => {
          setPatientAptData(response.data);
          setPar(response?.data);
          getProfileData(response?.data);
  
          setLaoding(false);
          if (response?.data?.status?.isSuccess === false) {
            // navigate('/diary');
            setLaoding(false);
          } else {
            setProfileData(response?.data);
          }
        })
        .catch((error) => {
          setLaoding(false);
          // navigate('/diary');
        });
    }else{
      getProfileData(userData);
      setPatientAptData(userData);
      setPar(userData);
      setProfileData(userData);
    }
  }, [userData,id]);
  useEffect(() => {
  }, [patientAptData]);

  return (
    <>
      <div className="profile_header">
        {loading ? (
          <Row>
            <Col sm={12}>
              <Skeleton variant="rectangular" width={'100%'} height={80} />
            </Col>
          </Row>
        ) : (
          <Row className="">
            <Col sm={12} className="profile_header_rightSide  py-3">
              <Row className="">
                <Col sm={6}>
                  <div style={{ textTransform: 'capitalize' }}>
                    <b>Name:</b>{' '}
                    <span style={{ textTransform: 'capitalize' }}>
                      {patientAptData?.firstName} {patientAptData?.lastName}
                    </span>
                  </div>
                  <div>
                    <b> Gender:</b> {patientAptData?.gender}
                  </div>
                  <div>
                    <b>Address:</b> <span style={{ textTransform: 'capitalize' }}>{patientAptData?.address}</span>
                  </div>
                  <div>
                    <b>City:</b> <span style={{ textTransform: 'capitalize' }}>{patientAptData?.city} </span>
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <b>T:</b> {patientAptData?.mobile}
                  </div>
                  <div>
                    <b>E:</b> {patientAptData?.email}
                  </div>
                  <div>
                    <b> DOB: </b>
                    {patientAptData?.dateOfBirth && moment(patientAptData?.dateOfBirth).format('DD/MM/YYYY')}
                    {}
                  </div>
                  {/* <div>
                  <b>Amount owed:</b> {patientAptData?.amount}£ 0
                </div> */}
                  <div>
                    <b>Post Code:</b> <span style={{ textTransform: 'uppercase' }}>{patientAptData?.postCode}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      <div className="modal_show">
        <Modal show={showModal} centered onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>{/* <Modal.Title></Modal.Title> */}</Modal.Header>
          <Modal.Body>Please select an appointment for this constant form</Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ProfileBar;
